import { API_ENDPOINT, DOMAIN_NAME } from '@app/configs'
import { AppManager, UserManager } from '@app/managers'

const referer = new URL(DOMAIN_NAME).hostname

// app fetch wrapper todo: merge params
export const fetcher = async (
  url: string,
  body?: Record<string, any> | null,
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT',
  rp?: boolean | string
) => {
  const headers = new Headers()
  const download = typeof rp === 'string' && rp === 'download'
  const forceUrl = typeof rp === 'string' && (rp === 'force' || download)

  let data = { data: null as any, message: '', code: 200 }
  let source = null

  if (UserManager.token) {
    headers.append('Authorization', UserManager.token)
  }

  headers.append('Referer', referer)

  if (!download) {
    // todo: make optional content-tye
    if ((method === 'POST' && body) || method !== 'POST') {
      headers.append('Content-Type', 'application/json')
    }

    if (!rp) {
      headers.append('Connection', 'keep-alive')
    }
  }

  const ep = rp && !forceUrl ? API_ENDPOINT.slice(0, -4) : API_ENDPOINT

  try {
    source = await fetch(forceUrl ? url : ep + url, {
      headers,
      method: method || 'GET',
      body: body ? JSON.stringify(body) : undefined,
    })
  } catch (e) {
    let message = 'Unknown Error'
    if (e instanceof Error) {
      message = e.message
    }
    AppManager.toggleSnack(true, message, 'error')
  }

  // attempt to parse content to json if success
  if (source && source.ok) {
    try {
      if (download) {
        const blob = await source.blob()
        data = { data: blob, message: '', code: 200 }
      } else {
        data = await source.json()
      }
    } catch (e) {
      let message = 'Parse Error'
      if (e instanceof Error) {
        message = e.message
      }
      AppManager.toggleSnack(true, message, 'error')
    }
  }

  return data
}
