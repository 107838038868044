import {
  createContext,
  useContext,
  FC,
  useState,
  PropsWithChildren,
  Fragment,
  useCallback,
  useEffect,
} from 'react'
import { UserManager } from '@app/managers'
import { fetcher } from '@app/utils/fetcher'
import { LocalStorageKeys } from '@app/configs/app-config'
import { OnDataOptions } from '@apollo/client'

export const defaultAccount = {
  activeSubscription: false,
  authed: false,
  alertEnabled: false,
  analyticsDisabled: false,
  pdfEnabled: false,
  inited: false,
  profileVisible: process.env.NEXT_PUBLIC_PROFILES_ENABLE === 'true',
  token: '',
  role: 0,
}

const AppContext = createContext({
  account: defaultAccount,
  setAccountType: (_x: typeof defaultAccount) => {},
  onRoleAdjustedEvent: (_x: OnDataOptions<any>) => {},
  reloadAccount: async () => ({
    role: 0,
    jwt: '',
  }),
})

export const AuthProviderBase = AppContext.Provider

// Determine the initial account type via load client-side
export const AuthProviderWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [account, setAccountType] = useState(defaultAccount)

  useEffect(() => {
    const parsedToken = UserManager.jwtParsed

    setAccountType({
      activeSubscription: !UserManager.freeAccount,
      authed: !!UserManager.token,
      alertEnabled: !!UserManager?.user?.alertsEnabled,
      analyticsDisabled: !!UserManager?.user?.analyticsDisabled,
      pdfEnabled: !!UserManager?.user?.pdfEnabled,
      token: UserManager.token,
      role: parsedToken?.audience,
      profileVisible: false, // todo: move to mobx
      inited: true,
    })
  }, [])

  // reload an account JWT and role.
  const reloadAccount = useCallback(async () => {
    const { data } = await fetcher('/refresh')
    const nextToken = data?.jwt

    if (data) {
      // if the token does not match update only
      if (
        nextToken &&
        (UserManager?.token !== nextToken ||
          data?.role !== UserManager?.jwtParsed?.audience)
      ) {
        // incase localStorage is mutated
        try {
          localStorage.removeItem(LocalStorageKeys.ReloadRequired)
          UserManager.setJwt(nextToken)
        } catch (e) {
          console.error(e)
        }
        setAccountType((acc) => {
          const _role = data?.role ?? acc.role

          return {
            ...acc,
            role: data?.role ?? acc.role,
            token: nextToken ?? acc.token,
            activeSubscription: (_role >= 1 && _role <= 11) || _role === 100, // between the active plans
          }
        })

        return data
      }
    }
  }, [])

  const onRoleAdjustedEvent = useCallback(
    async ({ data: roleData }: OnDataOptions<any>) => {
      const newUser = roleData?.data?.roleAdjusted

      // new role - perform refresh
      if (
        newUser?.token !== UserManager?.token ||
        newUser?.role !== UserManager?.jwtParsed?.audience
      ) {
        try {
          await reloadAccount()
          await fetcher('/reset-reload', null, 'POST')
        } catch (e) {
          console.error(e)
        }
      }
    },
    [reloadAccount]
  )

  return (
    <AuthProviderBase
      value={{ account, setAccountType, reloadAccount, onRoleAdjustedEvent }}
    >
      {children}
    </AuthProviderBase>
  )
}

export const AuthProvider: FC<PropsWithChildren<{ load?: boolean }>> = ({
  children,
  load,
}) => {
  if (!load) {
    return <Fragment>{children}</Fragment>
  }
  return <AuthProviderWrapper>{children}</AuthProviderWrapper>
}

export function useAuthContext() {
  return useContext(AppContext)
}
