import { memo, useMemo, useState } from 'react'
import { MoreOptionsBase } from './more-base'
import { Menu } from '@headlessui/react'
import { useAuthContext } from '@app/components/providers/auth'
import { GrLock } from 'react-icons/gr'
import { classNames } from '@app/utils/classes'
import { btnStyles, getMoreMenuStyle } from './styles'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { MoreOptionsProps } from '@app/types/website-more-options'
import { ModalOptions } from '../enums'
import { apiRoute } from '@app/configs/api-route'
import { Link } from '@app/components/stateless/typo/link'
import { twMerge } from 'tailwind-merge'
// import { AppManager } from '@app/managers'
import { useToast } from '@app/@/components/ui/use-toast'
import { PopoverTrigger } from '@app/@/components/ui/popover'
import { STRIPE_KEY } from '@app/configs'
import { fetcher } from '@app/utils/fetcher'

const MoreOptionsComponent = (
  props: MoreOptionsProps & {
    togglePopOverEvent?: any
  }
) => {
  const { t } = useTranslation('dashboard')
  const { account } = useAuthContext()
  const { toast } = useToast()
  const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false)

  const {
    url,
    removePress,
    crawlWebsite,
    pageHeaders,
    index,
    handleMainClick,
    storageless,
    manualAuditPrompted,
    // togglePopOverEvent
    // verified,
  } = props

  const encodedUrl = useMemo(
    () => (url ? encodeURIComponent(typeof url === 'string' ? url : '') : ''),
    [url]
  )

  // const onAnchorRef = useCallback(
  //   (r: HTMLAnchorElement) => {
  //     if (!anchorRef.current) {
  //       anchorRef.current = r
  //       anchorRef.current.addEventListener('click', (_e: Event) => {
  //         // todo:   "download-audit": "Downloading the audit for {{url}}, please wait..."
  //         // AppManager.toggleSnack(true, 'Downloading...')
  //         toast({
  //           title: t('download-audit', { url }),
  //           // description: t('download-audit', { url }),
  //         })
  //       })
  //     }
  //   },
  //   [anchorRef, t, toast, url]
  // )

  // download the PDF file.
  const onDownloadPdfEvent = async () => {
    if (downloadingPDF) {
      return
    }

    toast({
      title: t('download-audit', { url }),
    })

    setDownloadingPDF(true)

    try {
      const { data } = await fetcher(
        `${apiRoute}/website/export-pdf?q=${encodedUrl}`,
        undefined,
        'GET',
        'download'
      )

      if (data) {
        const downloadURL = window.URL.createObjectURL(data)
        const a = document.createElement('a')

        a.href = downloadURL
        a.download = `audit-${url}.pdf`
        document.body.appendChild(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(downloadURL)
      } else {
        toast({
          title: 'Error',
          description: 'PDF does not exist',
        })
      }
    } catch (error) {
      console.error('Failed to download PDF:', error)
    } finally {
      setDownloadingPDF(false)
    }
  }

  return (
    <MoreOptionsBase {...props} index={index} storageless={storageless}>
      <Menu.Item>
        {({ active }) =>
          (account.activeSubscription && account.pdfEnabled) || !STRIPE_KEY ? (
            <button
              type='button'
              className={getMoreMenuStyle(active, account.activeSubscription)}
              onClick={onDownloadPdfEvent}
              disabled={downloadingPDF}
            >
              {t('export-pdf')}
            </button>
          ) : (
            <Link
              className={twMerge(
                getMoreMenuStyle(
                  active,
                  account.activeSubscription && account.pdfEnabled
                ),
                'hover:text-blue-500 dark:hover:text-blue-300',
                'no-underline'
              )}
              href={'/settings#pdf-audits'}
            >
              <span>{t('export-pdf')}</span>
              {!(account.activeSubscription && account.pdfEnabled) ? (
                <GrLock className='grIcon' />
              ) : null}
            </Link>
          )
        }
      </Menu.Item>

      <Menu.Item>
        {({ active }) => (
          <button
            onClick={crawlWebsite}
            className={getMoreMenuStyle(active, true)}
            type='button'
            disabled={!crawlWebsite}
          >
            {t('sync')}
          </button>
        )}
      </Menu.Item>

      {storageless ? null : (
        <>
          <Menu.Item
            as={PopoverTrigger}
            className={'w-full'}
            disabled={!account.activeSubscription}
            // onClick={togglePopOverEvent}
          >
            {({ active }) => (
              <div
                className={getMoreMenuStyle(active, account.activeSubscription)}
              >
                {t('edit')}
                {!account.activeSubscription ? (
                  <GrLock className='grIcon' />
                ) : null}
              </div>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                className={getMoreMenuStyle(active, account.activeSubscription)}
                type='button'
                disabled={!account.activeSubscription}
                onClick={handleMainClick(
                  pageHeaders,
                  ModalOptions.Headers,
                  false,
                  url as string
                )}
              >
                {t('headers-btn')}
                {!account.activeSubscription ? (
                  <GrLock className='grIcon' />
                ) : null}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={getMoreMenuStyle(active, account.activeSubscription)}
                type='button'
                disabled={!account.activeSubscription || manualAuditPrompted}
                onClick={handleMainClick(
                  pageHeaders,
                  ModalOptions.Manual,
                  false,
                  url as string
                )}
              >
                {manualAuditPrompted ? t('r-audit-pending') : t('r-audit')}
                {!account.activeSubscription ? (
                  <GrLock className='grIcon' />
                ) : null}
              </button>
            )}
          </Menu.Item>
        </>
      )}
      {typeof removePress === 'function' && !storageless ? (
        <div className='border-t dark:border-gray-800'>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={removePress}
                type='button'
                className={classNames(
                  btnStyles,
                  `rounded-b hover:text-red-700 dark:hover:text-red-200`,
                  active
                    ? 'bg-gray-200 text-red-500 dark:text-white dark:bg-red-900'
                    : 'text-red-700 dark:text-red-500'
                )}
              >
                {t('delete')}
              </button>
            )}
          </Menu.Item>
        </div>
      ) : null}
    </MoreOptionsBase>
  )
}

export const MoreOptions = memo(MoreOptionsComponent)
